<template>
  <div>
    <CampaignTable />
  </div>
</template>

<script>
import CampaignTable from '../components/campaign/CampaignTable.vue'

export default {
  components: {
    CampaignTable,
  },
}
</script>
