<template>
  <div class="main">
    <div style="display:flex;gap:5px">
      <button
        v-b-modal.add-campaign
        class="button"
      >
        <b-icon-plus />
        {{ $t('AddCampaign') }}
      </button>
      <PdfDocument
        :method-name="'getOrdersOverviewPDF'"
        :getter-name="'getOrdersPDF'"
        :pdf-name="'Test 123'"
        :is-pagination="true"
        :header-name="header"
        :object-value="objectPDF"
      />
    </div>
    <div style="width: 100%;padding-top: 16px; margin-top: 0px;">
      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('CampaignName') }}
            </th>
            <th>
              {{ $t('Ocassion') }}
            </th>
            <th>
              {{ $t('Budget') }}
            </th>
            <th>
              {{ $t('StartDate') }}
            </th>
            <th>
              {{ $t('EndDate') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="hover-row"
          >
            <td>SS24 Campaign</td>
            <td>New Collection</td>
            <td>1000</td>
            <td>10/11/2023</td>
            <td>01/12/2023</td>
          </tr>
          <tr
            class="hover-row"
          >
            <td>Holiday Campaign</td>
            <td>Holiday Collection</td>
            <td>1000</td>
            <td>01/12/2023</td>
            <td>15/01/2024</td>
          </tr>
          <tr
            class="hover-row"
          >
            <td>Jean Paul Gaultier</td>
            <td>New Collection</td>
            <td>1000</td>
            <td>01/12/2023</td>
            <td>15/01/2024</td>
          </tr>
          <tr
            class="hover-row"
          >
            <td>Westwood AW23</td>
            <td>New Collection</td>
            <td>1000</td>
            <td>01/12/2023</td>
            <td>15/01/2024</td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddCampaignModal
      @addCamaping="addCamaping"
    />
  </div>
</template>

<script>
import AddCampaignModal from '@/components/campaign/modal/AddCampaignModal.vue'
import PdfDocument from '@/components/PdfDocument/DownloadPDF.vue'

export default {
  components: {
    AddCampaignModal,
    PdfDocument,
  },
  data() {
    return {
      header: [
        {
          orderNumber: 'Order Number', orderType: 'Order Type', priceTotal: 'Price Total', paidTotal: 'Paid Total', itemCounter: 'Item Counter',
        },
      ],
      objectPDF: {
        pageSize: 50,
        year: 2024,
      },
    }
  },
  methods: {
    async addCamaping(obj) {
      await this.addCampaing({
        object: obj,
        successCallback: () => {
          this.campaingPagination({
            pageNumber: 1,
            pageSize: this.pageSize,
          });
        },
      })
    },
  },
}
</script>

<style scoped>
.hover-row td{
    padding: 9px;
    font-weight: 400;
    color: black;
}

.main{
    display: flex;
    flex-direction: column;
}


.team_table td {
  padding: 16px 9px !important;
}

.team_table thead {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

.team_table {
  width: 100%;
  font-size: 12px;
  text-align: left;
}

.hover-row {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

@media screen and (max-width: 1700px) {
  .main{
    padding: 75px 10px 15px 90px;
  }
}

@media screen and (max-width: 1200px) {
  .main{
    padding: 75px 10px 15px 10px;
  }
}
</style>
