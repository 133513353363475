<template>
  <div>
    <b-modal
      id="add-campaign"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="$t('Add Campaign')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between;">
          <div style="display: flex; justify-content: space-between;">
            <b-form-group
              id="input-group-1"
              :label="$t('Campaign Name')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.campaign"
                :placeholder="$t('Campaign Name')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              :label="$t('Campaign type')"
              label-for="input-2"
            >
              <vue-select
                id="input-2"
                v-model="form.campaignType"
                style="width: 224px"
                :options="campaignTypes"
                :placeholder="$t('Campaign type')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <b-form-group
              id="input-group-4"
              :label="$t('StartDate')"
              label-for="input-9"
            >
              <date-picker
                v-model="form.time1"
                type="date"
                value-type="format"
                :placeholder="$t('SelectD')"
                :format="'DD-MM-YYYY'"
                style="width: 224px;"
              />
            </b-form-group>
            <b-form-group
              id="input-group-4"
              :label="$t('EndDate')"
              label-for="input-9"
            >
              <date-picker
                v-model="form.time2"
                type="date"
                value-type="format"
                :placeholder="$t('SelectD')"
                :format="'DD-MM-YYYY'"
                style="width: 224px;"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <b-form-group
              id="input-group-1"
              :label="$t('Campaign Budget')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.campaignBudget"
                type="number"
                :placeholder="$t('CampaignBudget')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('Campaign Occasion')"
              label-for="input-1"
            >
              <vue-select
                id="input-1"
                v-model="form.campaignOccassion"
                style="width: 224px;"
                :options="campaigns"
                :placeholder="$t('Campaign Occasion')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import moment from 'moment-timezone';

export default {
  components: {
    DatePicker,
  },
  props: [],
  data() {
    return {
      form: {
        campaign: null,
        time1: null,
        time2: null,
        campaignBudget: null,
        campaignOccassion: null,
        campaignType: null,
      },
      campaigns: ['Birthday Campaign', 'Wedding Campaign'],
      campaignTypes: ['Email', 'Phone'],
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('addCampaing', this.form)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.campaignOccassion = ''
      this.form.campaignBudget = ''
      this.form.time1 = ''
      this.form.time2 = ''
      this.form.campaign = ''
      this.form.campaignType = ''
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
